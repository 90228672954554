import axios from "axios";
import { environment } from "../environments/environment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { request } from "http";

const api = axios.create({
  baseURL: environment.baseUrl,
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    // Add auth headers to the request config
    if (config.url?.includes("/admin/")) {
      const token_a = localStorage.getItem("token_a");
      if (token_a) {
        config.headers["Authorization"] = `Bearer ${token_a}`;
      }
    } else {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    // Modify response data here
    if (response.data.success || response.data.code === 200) {
    } else {
      toast.error(response.data.message);
    }
    return response;
  },
  (error) => {
    const errorMessage = error.response?.data?.message || "An error occurred";
    toast.error(errorMessage);
    return Promise.reject(error);
  }
);

export default api;
