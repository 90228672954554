import React from "react";
import { ShopItem } from "../../components/shop-item";

export const Shop = () => {
  return (
    <>
      <main className="web_padding_x web_padding_y">
        {/* <h2 className="web_heading bar_above">Shop</h2>
        <p className="web_paragraph_1 mt-5">Choose your Product</p>
        <div className="grid mt-6 gap-5 xl:gap-10 [grid-template-columns:repeat(auto-fill,minmax(270px,1fr))]">
          <ShopItem />
          <ShopItem />
          <ShopItem />
          <ShopItem />
          <ShopItem />
          <ShopItem />
          <ShopItem />
        </div> */}

        <div className="text-2xl font-bold text-center py-24 italic">Coming soon...</div>
      </main>
    </>
  );
};
