import React, { useEffect, useState } from "react";
import thankBottle from "../../../assets/thank.png";
import { useParams } from "react-router-dom";
import api from "../../../api/api";
import { environment } from "../../../environments/environment";
import { Skeleton, Stack } from "@mui/material";
import moment from "moment";

export const CampaignSingle_Updates = () => {
  const { id } = useParams();
  const fetchCampaignUpdates = async () => {
    try {
      const response = await api.get(
        `${environment.baseUrl}/campaign/fetch-update/${id}`
      );

      setCampaignUpdates(response.data.data?.campaign_updates.rows ?? null);
    } catch (error) {}
  };

  useEffect(() => {
    fetchCampaignUpdates();
  }, []);

  const [campaignUpdates, setCampaignUpdates] = useState<any[] | null>(null);

  return (
    <>
      <div className="grid gap-5">
        {campaignUpdates?.map((update, index) => (
          <div key={index} className="max-w-[450px]">
            <div className="flex flex-wrap gap-3 pr-4 justify-between items-center">
              <div className="rounded-full w-[53px] h-[53px] bg-black"></div>

              <div className="flex-1">
                <h5 className="font-bold text-lg">{update.name}</h5>
                {/* <p className="text-xs mt-1 text-[#717173]">Today 10:00</p> */}
                <p className="text-xs mt-1 text-[#717173]">
                  {moment(update.CreatedAt).format("DD, MMM YYYY")}
                </p>
              </div>

              <button className="outline-none flex items-center gap-[2px]">
                <span className="w-[4px] rounded-full h-[4px] bg-[#717173]"></span>
                <span className="w-[4px] rounded-full h-[4px] bg-[#717173]"></span>
                <span className="w-[4px] rounded-full h-[4px] bg-[#717173]"></span>
              </button>
            </div>

            <p className="mt-4">{update.text}</p>

            <div className="mt-4">
              <img
                src={update.image_url}
                className="[aspect-ratio:450/286] object-cover"
                alt=""
              />
            </div>
          </div>
        ))}
      </div>
      {campaignUpdates?.length === 0 && (
        // temp
        <>
          <div className="border border-dashed text-center border-gray-400 max-w-[450px] rounded-lg px-10 py-20">
            There are no updates yet!
          </div>
        </>
      )}
      {!campaignUpdates && (
        <div className="w-full max-w-[450]">
          <Stack spacing={1} direction={"row"}>
            <Skeleton variant="rounded" width={400} height={80} />
            <Skeleton variant="rounded" width={400} height={80} />
            <Skeleton variant="rounded" width={400} height={80} />
            <Skeleton variant="rounded" width={400} height={80} />
            <Skeleton variant="rounded" width={400} height={80} />
          </Stack>
        </div>
      )}
    </>
  );
};
