import React from "react";
import PreferenceComponent from "../../components/PreferenceComponent";
import { DashboardTitle } from "../../components/DashboardTitle";

const PrivacyPreferences2 = () => {
  return (
    <section>
      <DashboardTitle text="Privacy Preferences"></DashboardTitle>
      <PreferenceComponent />
    </section>
  );
};

export default PrivacyPreferences2;
