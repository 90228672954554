import React from "react";
import hamburger from "../assets/icons/black-hamburger.svg";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../store/shared/actions";

export const DashboardTitle = ({ text }: { text: string }) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="flex items-center gap-3">
        <img
          onClick={() => dispatch(toggleSidebar())}
          src={hamburger}
          alt="hamburger-icon"
          className="exempt_sidebar_close w-[18px] cursor-pointer"
        />
        <h5 className="text-base md:text-2xl font-bold">{text}</h5>
      </div>
    </>
  );
};
