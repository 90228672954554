import { Outlet } from "react-router-dom";
import { WebsiteFooter } from "../components/website-footer";
import { WebsiteHeader } from "../components/website-header";

export const LandingLayout = () => {
  return (
    <>
      <WebsiteHeader />
      <Outlet />
      <WebsiteFooter />
    </>
  );
};
