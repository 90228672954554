import React, { useState } from "react";
import { DashboardTitle } from "../../components/DashboardTitle";
import Switch from "react-switch";
import Toggle from "../../components/Toggle";

export const Settings = () => {
  const [checked, setChecked] = useState(false);
  return (
    <section className="max-w-[400px]">
      <DashboardTitle text="Settings"></DashboardTitle>
      <div className="flex flex-col gap-5 mt-5">
        <div className="flex items-center justify-between font-bold">
          <p className="text-lg">Face ID</p>
          <Toggle />
        </div>

        <div className="flex items-center justify-between font-bold">
          <p className="text-lg">Two-Step Verification</p>
          <Toggle />
        </div>
      </div>
    </section>
  );
};
