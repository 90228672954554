import { ICampaignAdmin } from "../../../models/admin/campaigns";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const SET_SINGLE_CAMPAIGN = "SET_SINGLE_CAMPAIGN";
export const CLEAR_SINGLE_CAMPAIGN = "CLEAR_SINGLE_CAMPAIGN";

export const loginSuccess = (token: string) => {
  return {
    type: LOGIN_SUCCESS,
    data: { token },
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const setSingleCampaign = (campaignDetails: ICampaignAdmin) => {
  return {
    type: SET_SINGLE_CAMPAIGN,
    data: { campaignDetails },
  };
};

export const clearSingleCampaign = () => {
  return {
    type: CLEAR_SINGLE_CAMPAIGN,
  };
};
