import { ICampaignAdmin } from "../../models/admin/campaigns";
import {
  CLEAR_SINGLE_CAMPAIGN,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_SINGLE_CAMPAIGN,
} from "./actions/actions";
import jwt_decode from "jwt-decode";

export interface IAuthState {
  token: string | null;
  singleCampaign: ICampaignAdmin | null;
}

let token = localStorage.getItem("token");
const user = localStorage.getItem("user");

// Check if the token exists and is valid
if (token && user) {
  try {
    const decodedToken = jwt_decode(token);
  } catch (error) {
    token = null;
    localStorage.clear();
    sessionStorage.clear();
  }
} else {
}

const initialState: IAuthState = {
  token,
  singleCampaign: null,
};

export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.data.token,
      };
    case LOGOUT:
      localStorage.clear();
      window.location.replace("/");
      return {
        ...state,
        token: null,
      };
    case SET_SINGLE_CAMPAIGN:
      return {
        ...state,
        singleCampaign: action.data.campaignDetails,
      };
    case CLEAR_SINGLE_CAMPAIGN:
      return {
        ...state,
        singleCampaign: null,
      };
    default:
      return state;
  }
};
