import React from 'react'
import Toggle from "./Toggle"

const PreferenceComponent = () => {
  return (
    <div className="mt-4 grid md:grid-cols-1 justify-center justify-items-center items-center md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 font-bold gap-5 lg:gap-10">
          <div className="rounded-lg w-[350px] h-[150px] shadow-md p-8 lg:py-10 lg:px-14 flex items-center justify-center gap-4 lg:gap-6">
            <p>Text not Given</p>
            <Toggle />
          </div>
          <div className="rounded-lg w-[350px] h-[150px]  shadow-md p-8 lg:py-10 lg:px-14 flex items-center justify-center gap-4 lg:gap-6">
            <p>Text not Given</p>
            <Toggle />
          </div>
          <div className="rounded-lg w-[350px] h-[150px]  shadow-md p-8 lg:py-10 lg:px-14 flex items-center justify-center gap-4 lg:gap-6">
            <p>Text not Given</p>
            <Toggle />
          </div>
          <div className="rounded-lg w-[350px] h-[150px]  shadow-md p-8 lg:py-10 lg:px-14 flex items-center justify-center gap-4 lg:gap-6">
            <p>Text not Given</p>
            <Toggle />
          </div>
          <div className="rounded-lg shadow-md w-[350px] h-[150px]  p-8 lg:py-10 lg:px-14 flex items-center justify-center gap-4 lg:gap-6">
            <p>Text not Given</p>
            <Toggle />
          </div>
          <div className="rounded-lg shadow-md w-[350px] h-[150px]  p-8 lg:py-10 lg:px-14 flex items-center justify-center gap-4 lg:gap-6">
            <p>Text not Given</p>
            <Toggle />
          </div>

      </div>
  )
}

export default PreferenceComponent