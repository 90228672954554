import React from "react";
import { AiOutlineLoading } from "react-icons/ai";

interface ButtonProps {
  text: string;
  className?: string;
  icon?: React.ReactNode;
  iconPosition?: "start" | "end";
  type?: "button" | "reset" | "submit";
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick?: any;
}

export const BlackButton = ({
  className,
  icon,
  iconPosition,
  text,
  type,
  isLoading,
  isDisabled,
  onClick,
}: ButtonProps) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${
        className +
        " flex items-center justify-center gap-2 px-6 py-2 rounded-[24px] bg-black text-white focus:outline-none focus:shadow-outline active:rotate-y-180 active:scale-100" +
        `${
          isDisabled || isLoading
            ? " opacity-50 "
            : " transform transition duration-300 perspective-origin-center hover:-rotate-y-6 hover:scale-[1.02] "
        }` +
        `${icon ? " flex items-center gap-2 " : ""}`
      }`}
      disabled={isLoading || isDisabled}
    >
      {icon}
      {text}
      {isLoading ? (
        <AiOutlineLoading className="animate-spin h-5 w-5" />
      ) : // <></>
      null}
    </button>
  );
};
