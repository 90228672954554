import { applyMiddleware, combineReducers, createStore } from "redux";
import { IAuthState, authReducer } from "./authentication/reducers";
import { ISharedState, sharedReducer } from "./shared/reducers";
// import { composeWithDevTools } from "redux-devtools-extension";
// import logger from "redux-logger";

export interface IAppState {
  auth: IAuthState;
  shared: ISharedState;
}

const reducers = combineReducers({ auth: authReducer, shared: sharedReducer });

const store = createStore(
  reducers
  // composeWithDevTools(applyMiddleware(logger))
);

export default store;
