import styled from "styled-components";

interface ButtonProps {
  name?: string;
  className?: string;
  icon?: React.ReactNode;
  color?: string;
  bg?: string;
  submit?: boolean;
  social?: React.ReactNode;
  onClick?: any
}

export const Button = ({
  className,
  name,
  icon,
  color,
  bg,
  submit,
  social,
  onClick
}: ButtonProps) => {
  return (
    <Wrapper
    onClick={onClick}
      className={`${
        className
          ? className + " relative px-6 py-2 rounded-[24px]"
          : "relative px-6 py-2 rounded-[24px]"
      }`}
      color={color}
      bg={bg}
      type={`${submit ? "submit" : "button"}`}
      social={social}
    >
      {social && <div className="social">{social}</div>}
      {name}
      {icon}
    </Wrapper>
  );
};

const Wrapper = styled.button<ButtonProps>`
  border: ${({ bg }) => (!bg ? "2px solid #000" : "none")};
  svg {
    display: inline;
    margin-left: 0.5rem;
  }
  color: ${({ color }) => (color ? color : "#000000")};
  background-color: ${({ bg }) => (bg ? bg : "transparent")};

  div.social {
    background-color: #fff;
    border-radius: 24px;
    display: grid;
    place-content: center;
    position: absolute;
    left: 8.5px;
    top: 50%;
    transform: translateY(-50%);
    height: 70%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    svg {
      margin: 0;
      font-size: 1.4rem;
    }
  }
`;
