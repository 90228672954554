import React from "react";
import black from "../../../assets/black-bottle-back.png";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/store";
import { Stack, Skeleton } from "@mui/material";

export const CampaignSingle_1 = () => {
  const campaignDetails = useSelector(
    (state: IAppState) => state.auth.singleCampaign
  );

  return (
    <div>
      <div className="font-bold text-2xl sm:text-[28px]">
        {campaignDetails?.campaign_title}
      </div>
      <div className="flex flex-col gap-6">
        <div className="sm:text-lg">
          {campaignDetails?.campaign_description}
        </div>
        <div>
          {campaignDetails?.image_url ? (
            <img
              className="[aspect-ratio:450/286] max-w-[400px] object-cover"
              src={campaignDetails?.image_url?.split(",")[0]}
              alt=""
            />
          ) : (
            <>
              <Stack spacing={1}>
                <Stack spacing={1} direction={"row"}>
                  <Skeleton variant="rounded" width={400} height={200} />
                  <Skeleton variant="rounded" width={400} height={200} />
                  <Skeleton variant="rounded" width={400} height={200} />
                </Stack>
                <Stack spacing={1} direction={"row"}>
                  <Skeleton variant="rounded" width={400} height={200} />
                  <Skeleton variant="rounded" width={400} height={200} />
                  <Skeleton variant="rounded" width={400} height={200} />
                </Stack>
              </Stack>
            </>
          )}
        </div>
      </div>
      <div className="mt-10"></div>
    </div>
  );
};
