import { NavLink, Outlet } from "react-router-dom";
import settingsIcon from "../assets/icons/settings-icon.svg";
import logoutIcon from "../assets/icons/log-out.svg";
import { WebsiteHeader } from "../components/website-header";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../store/store";
import { useEffect, useRef } from "react";
import { closeSidebar } from "../store/shared/actions";
import compass from "../assets/icons/compass.png";
import list from "../assets/icons/list.png";
import { AiOutlineLock } from "react-icons/ai";
import { VscBell } from "react-icons/vsc";
import { FiUser, FiUsers } from "react-icons/fi";
import { logout } from "../store/authentication/actions/actions";

export const UserAccountLayout = () => {
  const navOpen = useSelector((state: IAppState) => state.shared.navOpen);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("click", closeSideBar_, true);
    return () => {
      document.removeEventListener("click", closeSideBar_, true);
    };
  }, []);

  const closeSideBar_ = (e: MouseEvent) => {
    if (
      sidebarRef.current?.contains(e.target as Node) ||
      (e.target instanceof Element &&
        e.target.classList.contains("exempt_sidebar_close"))
    ) {
      // The click occurred within the sidebar or on an exempt element, so do nothing.
      return;
    }
    dispatch(closeSidebar());
  };

  return (
    <>
      <div className="sticky top-0 bg-white">
        <WebsiteHeader />
      </div>
      <div className="">
        <div
          ref={sidebarRef}
          className={`fixed bg-black shadow w-[270px] z-10 flex flex-col justify-between h-[calc(100vh-110px)] overflow-y-auto duration-700 ${
            navOpen ? "translate-x-0" : "translate-x-[-1000px]"
          } lg:!translate-x-0`}
        >
          <nav className="mt-5 font-montserrat text-white">
            <NavLink
              to="/account/profile"
              className="flex items-center gap-3 py-2 pl-8 2xl:px-10 pr-4 admin_nav"
            >
              {/* <img src={settingsIcon} className="w-6" /> */}
              <FiUser className="text-3xl" />
              <span className="text-lg">Edit Profile</span>
            </NavLink>
          </nav>
          <nav className="mt-5 font-montserrat text-white">
            <NavLink
              to="/account/campaigns"
              className="flex items-center gap-3 py-2 pl-8 2xl:px-10 pr-4 admin_nav"
            >
              <img src={list} className="w-6" />
              <span className="text-lg">My Campaigns</span>
            </NavLink>
          </nav>
          <nav className="mt-5 font-montserrat text-white">
            <NavLink
              to="/account/payment-history"
              className="flex items-center gap-3 py-2 pl-8 2xl:px-10 pr-4 admin_nav"
            >
              <img src={compass} className="w-6" />
              <span className="text-lg">Payment History</span>
            </NavLink>
          </nav>
          <nav className="mt-5 font-montserrat text-white">
            <NavLink
              to="/account/help-center"
              className="flex items-center gap-3 py-2 pl-8 2xl:px-10 pr-4 admin_nav"
            >
              {/* <img src={settingsIcon} className="w-6" /> */}
              <FiUsers className="text-3xl" />
              <span className="text-lg">Help Center</span>
            </NavLink>
          </nav>
          <nav className="mt-5 font-montserrat text-white">
            <NavLink
              to="/account/settings"
              className="flex items-center gap-3 py-2 pl-8 2xl:px-10 pr-4 admin_nav"
            >
              <img src={settingsIcon} className="w-6" />
              <span className="text-lg">Settings</span>
            </NavLink>
          </nav>
          <nav className="mt-5 font-montserrat text-white">
            <NavLink
              to="/account/privacy-preferences"
              className="flex items-center gap-3 py-2 pl-8 2xl:px-10 pr-4 admin_nav"
            >
              {/* <img src={settingsIcon} className="w-6" /> */}
              <AiOutlineLock className="text-3xl" />
              <span className="text-lg">Privacy Preferences</span>
            </NavLink>
          </nav>
          <nav className="mt-5 font-montserrat text-white">
            <NavLink
              to="/account/notifications"
              className="flex items-center gap-3 py-2 pl-8 2xl:px-10 pr-4 admin_nav"
            >
              {/* <img src={settingsIcon} className="w-6" /> */}
              <VscBell className="text-3xl" />
              <span className="text-lg">Notifications Preferences</span>
            </NavLink>
          </nav>
          <div className="flex-grow"></div>
          <div className="font-montserrat text-white">
            <button
              onClick={() => {
                dispatch(logout());
              }}
              className="mt-auto mb-8 flex items-center gap-3 py-2 pl-8 2xl:px-10 pr-4 admin_nav"
            >
              <img src={logoutIcon} className="w-6" />
              <span className="text-lg">Logout</span>
            </button>
          </div>
        </div>
        <div className="md:min-h-[calc(100vh-110px)] overflow-y-auto lg:w-[calc(100vw-270px)] lg:ml-auto">
          <div className="px-6 sm:px-10 lg:px-8 2xl:px-14 pb-8 md:py-10">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
