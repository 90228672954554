import React from "react";
import { DashboardTitle } from "../../components/DashboardTitle";
import PreferenceComponent from "../../components/PreferenceComponent";

export const NotificationsPreferences = () => {
  return (
    <section>
      <DashboardTitle text="Notifications Preferences"></DashboardTitle>
      <PreferenceComponent />
    </section>
  );
};
