import { useEffect, useState } from "react";
import api from "../../api/api";
import { DashboardTitle } from "../../components/DashboardTitle";
import { environment } from "../../environments/environment";
import { ICampaignAdmin } from "../../models/admin/campaigns";
import { Stack, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import { FormattedDate, IntlProvider } from "react-intl";

export const MyCampaigns = () => {
  const fetchCampaigns = async () => {
    try {
      const response = await api.get(
        `${environment.baseUrl}/campaign/fetch/user`
      );

      const campaign_response = response.data.data?.campaign;
      if (campaign_response) {
        setCampaignsActive(
          campaign_response.filter((x: ICampaignAdmin) => x.status === "active")
        );

        setCampaignsInactive(
          campaign_response.filter((x: ICampaignAdmin) => x.status !== "active")
        );
      } else {
        setCampaignsActive([]);
        setCampaignsInactive([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const [campaignsActive, setCampaignsActive] = useState<
    ICampaignAdmin[] | null
  >(null);

  const [campaignsInactive, setCampaignsInactive] = useState<
    ICampaignAdmin[] | null
  >(null);

  return (
    <IntlProvider locale="en">
      <section>
        <DashboardTitle text="My Campaigns"></DashboardTitle>

        <div className="dashboard_subtitle mt-6 md:mt-4">Active Campaigns</div>

        {!campaignsActive && (
          <Stack spacing={1} direction={"row"}>
            <Skeleton variant="rounded" width={1000} height={100} />
            <Skeleton variant="rounded" width={1000} height={100} />
            <Skeleton variant="rounded" width={1000} height={100} />
          </Stack>
        )}

        {campaignsActive && campaignsActive.length ? (
          <div className="mt-7 shadow-md text-black p-8 tall:py-[60px] xl:px-9 rounded-[18px]">
            {campaignsActive.map((campaign, index) => (
              <div
                key={campaign.uuid}
                className={`grid gap-6 lg:grid-cols-[250px_auto_1fr] xl:gap-10  ${
                  index === 0 ? "" : " mt-5"
                }`}
              >
                <div className="aspect-[245/165] ">
                  <Link to={`/campaigns/${campaign.uuid}`}>
                    <img
                      src={campaign.image_url?.split(",")[0]}
                      className="w-full h-full object-cover object-top"
                      alt=""
                    />
                  </Link>
                </div>

                <div className="">
                  <Link to={`/campaigns/${campaign.uuid}`}>
                    <h4 className="font-bold text-[22px]">
                      {campaign.product_title}
                    </h4>
                  </Link>

                  <p className="font-bold text-sm mt-2">250% funded</p>
                  <p className="text-sm mt-2">By Robyn</p>
                </div>

                <div className="overflow-x-auto">
                  <table className="w-full whitespace-nowrap">
                    <thead>
                      <tr>
                        <th className="text-left font-normal pb-7">
                          <FormattedDate
                            value={campaign.CreatedAt}
                            year="numeric"
                            month="short"
                            day="2-digit"
                          />
                        </th>
                        <th className="text-left pl-5 font-normal pb-7">
                          LOCATION
                        </th>
                        <th className="text-left pl-5 font-normal pb-7">
                          STATUS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-t border-solid border-t-black">
                        <td className="pt-7">FEB 20, 2023</td>
                        <td className="pt-7 pl-5">{campaign.location}</td>
                        <td className="pt-7 pl-5">
                          <div className="py-1 px-5 text-center w-fit mr-auto text-sm leading-[14px] rounded-[13px] bg-black text-white">
                            ACTIVE
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        ) : null}

        {campaignsActive && !campaignsActive.length ? (
          // temp
          <>
            <div className="border border-dashed text-center border-gray-400 rounded-lg px-10 py-20">
              You have no active campaigns yet!
            </div>
          </>
        ) : null}

        <div className="dashboard_subtitle mt-4">Past Campaigns</div>

        {!campaignsInactive && (
          <Stack spacing={1} direction={"row"}>
            <Skeleton variant="rounded" width={1000} height={100} />
            <Skeleton variant="rounded" width={1000} height={100} />
            <Skeleton variant="rounded" width={1000} height={100} />
          </Stack>
        )}

        {campaignsInactive && campaignsInactive.length ? (
          <div className="mt-7 shadow-md text-black p-8 tall:py-[60px] xl:px-9 rounded-[18px]">
            {campaignsInactive.map((campaign, index) => (
              <div
                key={campaign.uuid}
                className={`grid gap-6 lg:grid-cols-[250px_auto_1fr] xl:gap-10  ${
                  index === 0 ? "" : " mt-5"
                }`}
              >
                <div className="aspect-[245/165] ">
                  <Link to={`/campaigns/${campaign.uuid}`}>
                    <img
                      src={campaign.image_url?.split(",")[0]}
                      className="w-full h-full object-cover object-top"
                      alt=""
                    />
                  </Link>
                </div>

                <div className="">
                  <Link to={`/campaigns/${campaign.uuid}`}>
                    <h4 className="font-bold text-[22px]">
                      {campaign.product_title}
                    </h4>
                  </Link>

                  <p className="font-bold text-sm mt-2">250% funded</p>
                  <p className="text-sm mt-2">By Robyn</p>
                </div>

                <div className="overflow-x-auto">
                  <table className="w-full whitespace-nowrap">
                    <thead>
                      <tr>
                        <th className="text-left font-normal pb-7">
                          <FormattedDate
                            value={campaign.CreatedAt}
                            year="numeric"
                            month="short"
                            day="2-digit"
                          />
                        </th>
                        <th className="text-left pl-5 font-normal pb-7">
                          LOCATION
                        </th>
                        <th className="text-left pl-5 font-normal pb-7">
                          STATUS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-t border-solid border-t-black">
                        <td className="pt-7">FEB 20, 2023</td>
                        <td className="pt-7 pl-5">{campaign.location}</td>
                        <td className="pt-7 pl-5">
                          <div className="py-1 px-5 text-center w-fit mr-auto text-sm leading-[14px] rounded-[13px] bg-black opacity-50 text-white">
                            INACTIVE
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        ) : null}

        {campaignsInactive && !campaignsInactive.length ? (
          // temp
          <>
            <div className="border border-dashed text-center border-gray-400 rounded-lg px-10 py-20">
              You have no inactive campaigns yet!
            </div>
          </>
        ) : null}
      </section>
    </IntlProvider>
  );
};
