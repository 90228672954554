import React from "react";
import hamburger from "../../assets/icons/black-hamburger.svg";
import dummyUserImage from "../../assets/dummy/dummy-user.png";
import imageUploadIcon from "../../assets/icons/image-upload.png";

export const AdminSettings = () => {
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Handle image upload here
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    // Handle dropped file here
  };

  return (
    <section className="font-montserrat">
      <div className="flex items-center gap-3">
        <img src={hamburger} alt="campaigns-icon" className="w-[18px]" />
        <h5 className="text-[22px] font-bold">Edit Profile</h5>
      </div>

      <div className="mt-7 bg-white gap-6 lg:gap-x-8 2xl:gap-y-9 tall:gap-y-9 2xl:gap-x-[5%] text-black p-8 tall:py-[60px] xl:px-9 rounded-[18px] grid items-start md:grid-cols-[auto_1fr] xl:relative">
        <div className="text-base">PROFILE PICTURE</div>
        <div>
          <div
            className="border border-dashed border-gray-400 rounded-lg p-10"
            onDragOver={(event) => event.preventDefault()}
            onDrop={handleDrop}
          >
            <label
              htmlFor="image-upload"
              className="cursor-pointer flex flex-col items-center justify-center"
            >
              <img src={imageUploadIcon} alt="User" className="w-6" />
              <p className="text-black text-center text-sm mt-3">
                Drag your image here
              </p>
            </label>
            <input
              id="image-upload"
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleImageUpload}
            />
          </div>
          <div className="border mt-5 border-dashed border-[#BEBEBE] w-fit rounded-[27px] p-3">
            <img
              src={dummyUserImage}
              alt="User"
              className="rounded-full h-[120px] w-[120px]"
            />
          </div>
        </div>
        <div className="text-base self-center">NAME</div>
        <input
          type="text"
          className="outline-2 border md:max-w-[450px] 2xl:max-w-[40%] border-solid border-[#CCCCCC] rounded-[27px] py-2 md:py-3 px-6 md:px-8 text-xs md:text-sm "
        />
        <div className="text-base self-center">EMAIL</div>
        <input
          type="text"
          className="outline-2 border md:max-w-[450px] 2xl:max-w-[40%] border-solid border-[#CCCCCC] rounded-[27px] py-2 md:py-3 px-6 md:px-8 text-xs md:text-sm "
        />
        <div className="text-base self-center">CONTACT NUMBER</div>
        <input
          type="text"
          className="outline-2 border md:max-w-[450px] 2xl:max-w-[40%] border-solid border-[#CCCCCC] rounded-[27px] py-2 md:py-3 px-6 md:px-8 text-xs md:text-sm "
        />
        <div className="text-base self-center">YOUR ROLE</div>
        <input
          type="text"
          className="outline-2 border md:max-w-[450px] 2xl:max-w-[40%] border-solid border-[#CCCCCC] rounded-[27px] py-2 md:py-3 px-6 md:px-8 text-xs md:text-sm "
        />

        <div className="flex justify-end md:col-span-2 xl:absolute xl:right-9 xl:bottom-9">
          <button className="bg-black text-white flex items-center gap-2 py-2 px-6 rounded-[18px]  focus:outline-none focus:shadow-outline transform transition duration-300 perspective-origin-center hover:-rotate-y-6 hover:scale-[1.01] active:rotate-y-180 active:scale-100">
            UPDATE PROFILE
          </button>
        </div>
      </div>
    </section>
  );
};
