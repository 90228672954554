import { useEffect, useRef } from "react";
import api from "../../../api/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginSuccess } from "../../../store/authentication/actions/actions";
import { useDispatch } from "react-redux";

export const GoogleCallback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialized = useRef(false);

  useEffect(() => {
    const callbackQuery = window.location.href.split("?")[1];

    googleSignInCallback(callbackQuery);
  }, []);

  const googleSignInCallback = async (query: string) => {
    try {
      const response = await api.get("/auth/google/callback?" + query);

      if (response.data.success) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            name: response.data.data.name,
            email: response.data.data.email,
          })
        );

        toast.success(response.data.message);
        localStorage.setItem("token", response.data.data.token);
        localStorage.removeItem("user_a");

        // check if there's a campaign the user wants to back
        const campaignToBack = sessionStorage.getItem("campaignToBack");
        if (campaignToBack) {
          navigate(`/back-campaign/${campaignToBack}`);
        } else {
          navigate("/account/profile");
        }
        dispatch(loginSuccess(response.data.data.token));
      }
    } catch (error) {
    } finally {
    }
  };
  return <></>;
};
