import { useId } from "react";
import styled from "styled-components";
import { useField, FieldAttributes } from "formik";

interface InputProps extends FieldAttributes<any> {
  name: string;
  label: string;
  placeholder?: string;
  icon?: React.ReactNode;
  required?: boolean;
  thin?: boolean;
}

const InputField = ({
  name,
  label,
  placeholder,
  icon,
  type,
  required,
  thin,
  ...props
}: InputProps) => {
  const id = useId();
  const [field, meta] = useField(name);

  return (
    <Wrapper thin={thin}>
      <label htmlFor={id}>{label}</label>
      <div className="">
        <div className="input-container">
          {icon}
          <input
            {...field}
            {...props}
            type={type}
            id={id}
            placeholder={placeholder}
            required={required}
          />
        </div>
        {meta.touched && meta.error && (
          <div className="text-red-500 text-sm">{meta.error}</div>
        )}
      </div>
    </Wrapper>
  );
};

interface WrapperProps {
  thin?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: grid;
  grid-template-columns: 85px 1fr;
  align-items: center;
  /* justify-content: space-between; */
  label {
    font-weight: ${({ thin }) => (thin ? "" : 700)};
  }
  .input-container {
    position: relative;
  }
  svg {
    font-size: 1.5rem;
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
  }
  input {
    padding: 0.5em 3em;
    // outline: ;
    border: 1.5px solid black;
    border-radius: 21px;
    display: block;
    width: 100%;
    max-width: 550px;
  }
`;

export default InputField;
